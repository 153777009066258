import { useState, useEffect, useCallback } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { Nav, Alert } from "src/components";
import SideBar from "src/components/Sidebar";
import AppContext from "src/components/AppContext";
import { DashboardObject } from "src/helpers";
import { dashboardService, userService } from "src/services";
import "public/styles/globals.css";
import "public/styles/consumer.css";

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dashboards, setDashboards] = useState<DashboardObject[]>([]);
  const [selectedDashboards, setSelectedDashboards] = useState<
    DashboardObject[]
  >([]);

  const authCheck = useCallback(
    (url: string) => {
      // redirect to login page if accessing a private page and not logged in
      const publicPaths = ["/account/login", "/account/register", "/consumer"];
      const path = url.split("?")[0];
      if (!userService.userValue && !publicPaths.includes(path)) {
        setAuthorized(false);
        router.push({
          pathname: "/consumer",
        });
      } else {
        setAuthorized(true);
        dashboardService.getAll().then((x) => setDashboards(x));
      }
    },
    [router],
  );

  useEffect(() => {
    // dashboardService.getAll().then((x) => setDashboards(x));
    // on initial load - run auth check
    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on("routeChangeStart", hideContent);

    // on route change complete - run auth check
    router.events.on("routeChangeComplete", authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", authCheck);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboards.length === 0) return;
    const dashboard = dashboards.filter((d) => !d.disabled)[0];
    const records = [];
    if (dashboard) records.push(dashboard);
    setSelectedDashboards(records);
  }, [dashboards]);

  const isConsumerRoute = router.asPath.includes("/consumer");

  return (
    <>
      <Head>
        <title>Abillion - by Atharva System</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-status-bar-style" content="black" />
        <link rel="icon" type="image/x-icon" href="images/favicon.png" />
      </Head>

      <AppContext.Provider
        value={{
          state: {
            currentUser: userService.userValue,
            dashboards: dashboards,
            selectedDashboards: selectedDashboards,
            setShowLoader: setShowLoader,
            setDashboards: setDashboards,
            setSelectedDashboards: setSelectedDashboards,
          },
        }}
      >
        <div
          className={`app-container ${isConsumerRoute ? "consumer-page" : ""}`}
        >
          <Script src="/js/ag-grid-community.min.js"></Script>
          <Script
            src="/js/bootstrap.bundle.min.js"
            crossOrigin="anonymous"
          ></Script>

          <div className="container-fluid g-0">
            <div className="row flex-nowrap">
              <div className="col-auto px-0">{authorized && <SideBar />}</div>
              <main>
                <div
                  className={
                    "overlay d-flex justify-content-center align-items-center" +
                    (showLoader ? "" : " d-none")
                  }
                >
                  <div
                    className="spinner-grow text-success"
                    role="status"
                    style={{ width: "3rem", height: "3rem", zIndex: 20 }}
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
                <Nav />
                <Alert />
                {authorized && <Component {...pageProps} />}
              </main>
            </div>
          </div>
        </div>
      </AppContext.Provider>
    </>
  );
};
export default App;
